import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./../../utils/axios";
import { openSnackBar } from "./snackbar";
const initialState = {
  user: [],
  status: "idle",
  error: null,
  statusUpdateProfile: "idle",
  statusUpdatePassword: "idle",
  errorUpdateProfile: null,
};

export const fetchUser = createAsyncThunk("user/fetchUser", async () => {
  let data;
  try {
    const response = await axios.get(`/admin/me`);
    data = response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const updateProfile = createAsyncThunk(
  "user/updateProfile",
  async (user, thunkAPI) => {
    console.log(user);
    let data;
    try {
      const response = await axios.patch(`/admin/update/me`, user);
      data = await response.data.data;
      if (response.status === 200) {
        thunkAPI.dispatch(
          openSnackBar({
            status: "success",
            msg: "user updated successfully",
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(
        openSnackBar({
          status: "error",
          msg: err.message,
        })
      );
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const UpdatePassword = createAsyncThunk(
  "user/UpdatePassword",
  async (passwords, thunkAPI) => {
    let data;
    try {
      const response = await axios.patch(`/admin/updatePassword`, passwords);
      data = await response.data.data;
      if (response.status === 200) {
        thunkAPI.dispatch(fetchUser());
        thunkAPI.dispatch(
          openSnackBar({
            status: "success",
            msg: "password  updated successfully",
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(
        openSnackBar({
          status: "error",
          msg: err.message,
        })
      );
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
const slice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUser.pending]: (state) => {
      state.status = "loading";
    },
    [fetchUser.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.user = action.payload.data;
    },
    [fetchUser.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [updateProfile.pending]: (state) => {
      state.statusUpdateProfile = "loading";
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.statusUpdateProfile = "succeeded";
      state.user = action.payload;
    },
    [updateProfile.rejected]: (state, action) => {
      state.statusUpdateProfile = "failed";
      state.error = action.payload;
    },
    [UpdatePassword.pending]: (state) => {
      state.statusUpdatePassword = "loading";
    },
    [UpdatePassword.fulfilled]: (state, action) => {
      state.statusUpdatePassword = "succeeded";
      state.user = action.payload;
    },
    [UpdatePassword.rejected]: (state, action) => {
      state.statusUpdatePassword = "failed";
      state.error = action.payload;
    },
  },
});
export const { setNewAvatar, emptyAvatar } = slice.actions;
export const reducer = slice.reducer;
export default slice;
