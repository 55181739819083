import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Toolbar from "../../components/Toolbar/Toolbar";
import Table from "../../components/Table/Table";
import Collapse from "@material-ui/core/Collapse";
import Columns from "../../components/Columns";
import SnackBar from "../../components/SnackBar";
import Filter from "../../components/Filter/Filter";
import { fetchWebsites } from "../../redux/slices/websites";
import { useDispatch, useSelector } from "react-redux";
import SearchValue from "./../../utils/Search"
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const columnsList = [
  {
    accessor: "id",
    name: "id",
    label: "Id",
    width: "7%",
    show: true,
    sortable: true,
  },
  {
    accessor: "websiteID",
    name: "websiteID",
    label: "websiteID",
    width: "10%",
    show: true,
    sortable: true,
  },
  {
    accessor: "name",
    name: "name",
    label: "name",
    width: "20%",
    show: true,
    sortable: true,
  },
  {
    accessor: "icon",
    label: "icon",
    width: "20%",
    show: true,
    type: "fieldValue",
  },
  {
    accessor: "domain",
    label: "domain",
    width: "20%",
    show: true,
    type: "fieldValue",
  },
  {
    accessor: "url",
    label: "url",
    width: "20%",
    show: true,
    type: "fieldValue",
  },
  {
    accessor: "email",
    label: "email",
    width: "20%",
    show: true,
    type: "fieldValue",
  },
  {
    accessor: "updated_at",
    type: "date",
    width: "25%",
    label: "Updated At",
    show: true,
  },
  {
    accessor: "created_at",
    type: "date",
    width: "25%",
    label: "Created At",
    show: true,
  },
];
const fieldSearchable = ["id"];
const fieldFilterable = [
  { name: "division.id", label: "Division", type: "divisionAutocomplete" },
  {
    name: "affiliations.group.id",
    label: "User group",
    type: "userGroupAutocomplete",
  },
  { name: "points", label: "Points", type: "numberRange" },
  { name: "createdAt", label: "Created at", type: "dateRange" },
  {
    name: "codes",
    label: "Faithful Users(Codes>=3)",
    type: "dropdown",
    options: ["thisYear", "lastYear", "all"],
  },
  { name: "state.id", label: "State", type: "stateAutocomplete" },
  { name: "affiliation", label: "Affiliations Expire in", type: "number" },
  {
    name: "allYearAffiliation",
    label: "AllYear Subscriptions",
    type: "dropdown",
    options: ["yes", "no"],
  },
  { name: "verified", label: "Verified", type: "boolean" },
  { name: "completed", label: "Completed", type: "boolean" },
  { name: "noAffiliation", label: "Users Without Offers", type: "boolean" },
  { name: "withoutEmail", label: "Users Without Email", type: "boolean" },
  {
    name: "withoutOptSubject",
    label: "Users Without Optional Subjects",
    type: "boolean",
  },
  { name: "studentLevelUser", label: "Users With Levels", type: "boolean" },
  { name: "istest", label: "Test User", type: "boolean" },
];

const WebsitesList = () => {
  const [search, searchChange] = useState("");
  const [filters, filtersChange] = useState({});
  const [columns, columnsChange] = useState(columnsList);
  const [sort, sortChange] = useState({ accessor: "id", order: "desc" });
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [columnsOpen, setColumnsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

 
  const { meta } = useSelector((state) => state.websites);
  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };
  const toggleColumns = () => {
    setColumnsOpen(!columnsOpen);
  };
  const handleSortChange = (accessor) => {
    sortChange({
      accessor: accessor,
      order: sort.order === "asc" ? "desc" : "asc",
    });
  };
  const { open, msg } = useSelector((state) => state.snackbar);
  const handlePageChange = (event, page) => {
    setPage(page + 1);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const classes = useStyles();
  if (!data.payload) {
    // return (<Loading/>);
  }
  const dispatch = useDispatch();
  useEffect(() => {
    const controller = new AbortController();
    dispatch(fetchWebsites(`?page=${page}&perPage=${rowsPerPage}`));
    return () => {
      controller.abort();
    };
  }, [rowsPerPage, page, dispatch]);
  const { websites } = useSelector((state) => state.websites);
  
  return (
    <div className={classes.root}>
      <SnackBar open={open} msg={msg} />
      <Toolbar
        toggleFilters={toggleFilters}
        toggleColumns={toggleColumns}
        searchChange={searchChange}
        pageLink={"/Websites"}
        searchMessage={"Search (ID, Name)"}
      />
      <div className={classes.content}>
        <Collapse in={columnsOpen}>
          <Columns columnsChange={columnsChange} columns={columns} />
        </Collapse>
        <Collapse in={filtersOpen}>
          <Filter
            fields={fieldFilterable}
            values={filters}
            filtersChange={filtersChange}
          />
        </Collapse>
        <Table
          columns={columns}
          data={SearchValue(websites,search.trim())}
          meta={meta}
          handleSortChange={handleSortChange}
          sort={sort}
          handlePageChange={handlePageChange}
          page={page}
          tableService={""}
          rowsPerPage={rowsPerPage}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      </div>
    </div>
  );
};

export default WebsitesList;
