// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, TextField} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import schemaParser from '../../../helpers/schemaParser';
import FormHelperText from '@material-ui/core/FormHelperText';


const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
 
}));

let schema = {};
const getSchema = () => {
  schemaParser('divisions').then((data) => {
    schema = data;
  });
};
type Props = {
  edit: boolean,
  className: string
}
const Form = (props: Props): React$Element<any> => {
  const {id} = useParams();
  const {className, edit, ...rest} = props;
  const classes = useStyles();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertSeverity] = useState('success');
  const [open, setOpen] = React.useState(false);

  useEffect(() => {

  }, []);
  const handleChange = (event) => {

  };

  const handleSubmit = () => {
  };

  const handleClose = () => {
    setOpen(false);
  };

  const hasError = (field) =>
      formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <form
          autoComplete="off"
          noValidate
        >
          <CardHeader
            subheader="Divisions form"
            
          />
          <Divider/>
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={4}
                xs={12}
              >
                <TextField
                  error={hasError('name')}
                  fullWidth
                  helperText={
                      hasError('name') ? formState.errors.name[0] : null
                  }
                  label="Name"
                  name="name"
                  onChange={handleChange}
                  value={formState.values.name || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid
                  item
                  md={4}
                  xs={12}
              >
                <TextField
                    error={hasError('position')}
                    fullWidth
                    helperText={
                      hasError('position') ? formState.errors.name[0] : null
                    }
                    label="Position"
                    name="position"
                    onChange={handleChange}
                    type="number"
                    value={formState.values.position || ''}
                    variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
              >
                <FormControl component="fieldset">
                  <FormLabel component="legend">Public</FormLabel>
                  <RadioGroup aria-label="Public" name="isPublic"
                    onChange={handleChange}
                    value={formState.values.isPublic || ''}
                    error={hasError('isPublic').toString()}
                    row>
                    <FormControlLabel control={<Radio/>} value="1"
                      label="Yes"/>
                    <FormControlLabel control={<Radio/>} value="0"
                      label="No"/>
                  </RadioGroup>
                  {hasError('isPublic') && <FormHelperText>{formState.errors.isPublic[0]}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>

          </CardContent>
          <Divider/>
          <CardActions>
            <Button
              disabled={!formState.isValid}
              color="primary"
              variant="contained"
              onClick={handleSubmit}>
                            Save details
            </Button>
          </CardActions>
        </form>
      </Card>
      <SnackBar open={open} message={message} severity={severity} handleClose={handleClose}/>
    </div>
    
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
loading: PropTypes.bool,

};

export default Form;
