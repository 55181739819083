import { combineReducers } from "@reduxjs/toolkit";
import { reducer as websitesReducer } from "./../redux/slices/websites";
import { reducer as snackbarReducer } from './slices/snackbar';
import {reducer as superAdminsReducer } from "./slices/SuperAdmins"
import {reducer as userReducer} from "./slices/user"
const combinedReducer = combineReducers({
  websites: websitesReducer,
  snackbar: snackbarReducer,
  superAdmins:superAdminsReducer,
  user:userReducer,
});
const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return combinedReducer(state, action);
};
export default rootReducer;
