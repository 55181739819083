import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./../../utils/axios";
import { openSnackBar } from "./snackbar";
const initialState = {
  superAdmins: [],
  websiteID: "",
  superAdminTotalNbr: 0,
  totalnbrstatus: "idle",
  websiteIdStatus: "idle",
  fetchstatus: "idle",
  error: null,
};
export const fetchSuperAdmins = createAsyncThunk(
  "superAdmins/fetchSuperAdmins",
  async (websiteID) => {
    let data;
    try {
      const response = await axios.get(
        `/clients/websites/${websiteID}/super-admins`
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const fetchTotalNumberSuperAdmins = createAsyncThunk(
  "superAdmins/fetchSuperAdmins",
  async (websiteID) => {
    let data;
    try {
      const response = await axios.get(`/owner/super-admins`);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const fetchWebsiteId = createAsyncThunk(
  "superAdmins/fetchWebsiteId",
  async () => {
    let data;
    try {
      const response = await axios.get(`owner/websites/website-id-generator`);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "superAdmins",
  initialState,
  reducers: {
    getWebsiteID: (state, action) => {
      localStorage.setItem("websiteID", action.payload);
      state.websiteID = action.payload;
    },
    clearWebsiteID: (state, action) => {
      state.websiteID = "";
    },
  },
  extraReducers: {
    [fetchSuperAdmins.pending]: (state) => {
      state.fetchstatus = "loading";
    },
    [fetchSuperAdmins.fulfilled]: (state, action) => {
      state.fetchstatus = "succeeded";
      state.superAdmins = action.payload.data.docs;
      state.meta = action.payload.data.meta;
    },
    [fetchSuperAdmins.rejected]: (state, action) => {
      state.superAdmins = "failed";
      state.error = action.payload;
    },
    [fetchTotalNumberSuperAdmins.pending]: (state) => {
      state.totalnbrstatus = "loading";
    },
    [fetchTotalNumberSuperAdmins.fulfilled]: (state, action) => {
      state.totalnbrstatus = "succeeded";
      state.superAdminTotalNbr = action.payload.data;
      state.meta = action.payload.data.meta;
    },
    [fetchTotalNumberSuperAdmins.rejected]: (state, action) => {
      state.totalnbrstatus = "failed";
      state.error = action.payload;
    },
    [fetchWebsiteId.pending]: (state) => {
      state.websiteIdStatus = "loading";
    },
    [fetchWebsiteId.fulfilled]: (state, action) => {
      state.websiteIdStatus = "succeeded";
      state.websiteID = action.payload.data;
    },
    [fetchWebsiteId.rejected]: (state, action) => {
      state.websiteIdStatus = "failed";
      state.error = action.payload;
    },
  },
});
export const reducer = slice.reducer;
export const { getWebsiteID, clearWebsiteID } = slice.actions;

export default slice;
