import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Delete from "../Action/Delete";
import {getItem} from "./../../redux/slices/websites"
import { getWebsiteID } from "redux/slices/SuperAdmins";
const Action = (props) => {
  const {
    itemId,
    item,
    tableService,
    pageLink,
    initializeData,
    rowsPerPage,
    ...rest
  } = props;
const dispatch=useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    setOpenDelete(!openDelete);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "20ch",
          },
        }}
      >
        <MenuItem component={Link} to={`/superadmin`} onClick={()=>dispatch(getWebsiteID(itemId))}>
          Show SuperAdmins
        </MenuItem>

        <MenuItem component={Link} to={"/website/Edit"} onClick={()=>dispatch(getItem(item))}>
          Edit
        </MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
      <Delete
        open={openDelete}
        handleDelete={handleDelete}
        itemId={itemId}
        service={tableService}
        pageLink={pageLink}
        rowsPerPage={rowsPerPage}
        initializeData={initializeData}
      />
    </div>
  );
};

Action.propTypes = {
  className: PropTypes.string,
  itemId: PropTypes.number,
  noShow: PropTypes.bool,
};

export default Action;
