import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Divider,
  makeStyles,
  Tab,
  Tabs,
} from "@material-ui/core";
import Page from "../../components/Page";
import UserInfo from "./WebsiteView/Index";
import { useParams } from "react-router-dom";
import SimpleToolbar from "../../components/Toolbar/SimpleToolbar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

const UserShow = (props) => {
  const { Link, pageLink, title, ...rest } = props;
  const { id } = useParams();
  const classes = useStyles();
  const [user, setUser] = useState([]);
  const [studentLevels, setStudentLevels] = useState([]);
  const [pointsHistory, setPointsHistory] = useState([]);
  const [currentTab, setCurrentTab] = useState("details");

  const tabs = [
    { value: "details", label: "Basics" },
    { value: "subscriptions", label: "Subscriptions" },
  ];

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  useEffect(() => {
    switch (currentTab) {
      case "details":
        break;
      case "subscriptions":
        break;
    }
  }, [currentTab, id]);
  if (!Object.keys(user).length && currentTab === "details")
    //return (<Loading/>);
    return (
      <div>
        <SimpleToolbar
          Link={Link}
          pageLink={"/users/students"}
          title={"Students"}
        />
        <Page className={classes.root} title="Customer Details">
          <Container maxWidth={false}>
            <Box mt={3}>
              <Tabs
                onChange={handleTabsChange}
                scrollButtons="auto"
                value={currentTab}
                variant="scrollable"
                textColor="secondary"
              >
                {tabs.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
            </Box>
            <Divider />
            <Box mt={3}>
              {currentTab === "details" && (
                <UserInfo
                  user={user}
                  levels={studentLevels}
                  pointsHistory={pointsHistory}
                />
              )}
            </Box>
          </Container>
        </Page>
      </div>
    );
};
export default UserShow;
