import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { deleteWebsite } from "./../../redux/slices/websites";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
}));
const Delete = (props) => {
  const dispatch = useDispatch();
  const {
    className,
    handleDelete,
    open,
    itemId,
    service,
    pageLink,
    initializeData,
    rowsPerPage,
    ...rest
  } = props;
  const classes = useStyles();
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Dialog
        open={open}
        onClose={handleDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to continue deleting?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              dispatch(deleteWebsite(itemId));
              handleDelete();
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

Delete.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  handleDelete: PropTypes.func,
  itemId: PropTypes.number,
};

export default Delete;
