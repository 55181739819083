import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { closeSnackBar } from "./../redux/slices/snackbar";
import React from "react";
const SnackBar = (props) => {
  const { open, msg } = useSelector((state) => state.snackbar);
  const dispatch = useDispatch();
  return (
    <Snackbar
      open={open}
      transitionDuration={200}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={2000}
      onClose={() => dispatch(closeSnackBar())}
    >
      <Alert
        severity={
          msg?.status === "success"
            ? "success"
            : msg?.status === "error"
            ? "error"
            : "success"
        }
      >
        {msg?.status === "success"
          ? msg.msg
          : msg?.status === "error"
          ? msg?.msg
          : ""}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
