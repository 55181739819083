export default function findInValues(arr, search) {
  /** cast to string and lowercase to have non-dependant type and case search */

  search = String(search).toLowerCase();
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const days = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];

  return arr.filter((object) =>
    /** get only values from iterated objects */
    Object.values(object).some((objValue) =>
      /** casting field values to the same shape */
      {
        return (days?.includes(search.split(",")[0].toUpperCase())
          ? new Date(objValue).toLocaleDateString(undefined, options)
          : objValue
        )
          .toString()
          .toLowerCase()
          .includes(search);
      }
    )
  );
}
