// @flow
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import {
  createSuperAdminInWebsite,
  createWebsite,
} from "../../../redux/slices/websites";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@material-ui/lab/TabPanel";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import { updateWebsite } from "../../../redux/slices/websites";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import SnackBar from "../../../components/SnackBar";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  clearWebsiteID,
  fetchSuperAdmins,
  fetchWebsiteId,
} from "redux/slices/SuperAdmins";
import SuperAdminList from "./../../SuperAdminList/SuperAdminList";
import Table from "components/Table/Table";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
  },
  content: {
    padding: 0,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      minWidth: "10px",
    },
  },
}));

const Form = (props) => {
  const dispatch = useDispatch();
  const {
    className,
    edit,
    item,
    actionid,
    website,
    superAdminAction,
    ...rest
  } = props;
  const history = useHistory();
  const [tab, setTab] = useState(1);

  const validationSchema =
    tab === 1
      ? Yup.object({
          name: Yup.string("Enter your name").required("name is required"),
          icon: Yup.string("Enter your icon").required("Icon is required"),
          websiteID:
            actionid === "edit"
              ? Yup.string("Enter your websiteID").notRequired()
              : Yup.string("Enter your websiteID").required(),
          domain: Yup.string("Enter your domain").required(
            "Domain is required"
          ),
          email: Yup.string("Enter your email")
            .email("Enter a valid email")
            .required("Email is required"),
          url: Yup.string("Enter your url").required("Url is required"),
        })
      : Yup.object({
          email: Yup.string("Enter your email")
            .email("Enter a valid email")
            .required("Email is required"),
          password: Yup.string("Enter your password")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
              "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            )
            .required("Password is required"),
          firstName: Yup.string("Enter your firstName").required(
            "First Name is required"
          ),
          lastName: Yup.string("Enter your lastName").required(
            "Last Name is required"
          ),
          phone: Yup.number("Enter your phone number").required(
            "Phone number is required"
          ),
          websiteID:
            actionid === "edit"
              ? Yup.string("Enter your websiteID").notRequired()
              : Yup.string("Enter your websiteID").required(),
        });
  const { websiteID, superAdmins } = useSelector((state) => state.superAdmins);

  useEffect(() => {
    const controller = new AbortController();
    if (actionid === "edit" && tab === 2) {
      dispatch(fetchSuperAdmins(website?.websiteID));
    }
    return () => {
      controller.abort();
    };
  }, [actionid, tab, website?.websiteID]);

  const formik = useFormik({
    initialValues: {},
    validationSchema: validationSchema,
    onSubmit: (values) => {
      actionid === "create" &&
        tab === 1 &&
        dispatch(createWebsite(values)).then((res) => {
          if (res.meta.requestStatus === "fulfilled") {
            formik.resetForm();
            setTab((prev) => prev + 1);
          }
        });
      (actionid === "create" || actionid === "edit") &&
        tab === 2 &&
        dispatch(createSuperAdminInWebsite(values)).then((res) => {
          if (res.meta.requestStatus === "fulfilled") {
            dispatch(clearWebsiteID());
            history.push("/websites");
          }
        });
      actionid === "edit" &&
        tab === 1 &&
        dispatch(updateWebsite({ ...values, id: website._id })).then((res) => {
          if (res.meta.requestStatus === "fulfilled") {
            dispatch(clearWebsiteID());
            history.push("/websites");
          }
        });
    },
  });

  const classes = useStyles();
  const [message, setAlertMessage] = useState("");
  const [severity, setAlertSeverity] = useState("");
  const [open, setOpen] = useState(false);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    actionid === "create" &&
      tab === 1 &&
      formik.setValues(() => {
        return {
          websiteID: websiteID,
          name: "",
          icon: "",
          domain: "",
          url: "",
          email: "",
        };
      });

    actionid === "create" &&
      tab === 2 &&
      formik.setValues(() => {
        return {
          websiteID: websiteID,
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          password: "",
        };
      });

    actionid === "edit" &&
      tab === 1 &&
      formik.setValues(() => {
        return {
          name: website.name,
          icon: website.icon,
          domain: website.domain,
          email: website.email,
          url: website.url,
        };
      });

    actionid === "edit" &&
      tab === 2 &&
      formik.setValues(() => {
        return {
          websiteID: website?.websiteID,
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          password: "",
        };
      });
  }, [actionid, website, tab, websiteID]);

  const handleClose = () => {
    setOpen(false);
  };
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handlePageChange = (event, page) => {
    setPage(page + 1);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };
  const columnsList = [
    {
      accessor: "id",
      name: "id",
      label: "Id",
      width: "7%",
      show: true,
      sortable: true,
    },
    {
      accessor: "websiteID",
      name: "websiteID",
      label: "websiteID",
      width: "10%",
      show: true,
      sortable: true,
    },
    {
      accessor: "firstName",
      name: "name",
      label: "firstName",
      width: "20%",
      show: true,
      sortable: true,
    },
    {
      accessor: "lastName",
      label: "lastName",
      width: "20%",
      show: true,
      type: "fieldValue",
    },
    {
      accessor: "Email",
      label: "Email",
      width: "20%",
      show: true,
      type: "fieldValue",
    },
    {
      accessor: "Role",
      label: "Role",
      width: "20%",
      show: true,
      type: "fieldValue",
    },
    {
      accessor: "Availability",
      label: "Availability",
      width: "20%",
      show: true,
      type: "fieldValue",
    },
    {
      accessor: "isVerified",
      label: "isVerified",
      width: "20%",
      show: true,
      type: "fieldValue",
    },
    {
      accessor: "updated_at",
      type: "date",
      width: "25%",
      label: "Updated At",
      show: true,
    },
    {
      accessor: "created_at",
      type: "date",
      width: "25%",
      label: "Created At",
      show: true,
    },
  ];
  const [sort, sortChange] = useState({ accessor: "id", order: "desc" });
  const handleSortChange = (accessor) => {
    sortChange({
      accessor: accessor,
      order: sort.order === "asc" ? "desc" : "asc",
    });
  };
  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <TabContext value={tab}>
          <div className={classes.header}>
            {actionid === "create" ? (
              <TabList
                scrollButtons="auto"
                variant="scrollable"
                className={classes.header}
                onChange={handleChangeTab}
                aria-label="simple tabs example"
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Website-ID" value={1} />
                <Tab label="Super-admin" value={2} />
              </TabList>
            ) : (
              <TabList
                scrollButtons="auto"
                variant="scrollable"
                className={classes.header}
                onChange={handleChangeTab}
                aria-label="simple tabs example"
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Website-ID" value={1} />
                <Tab label="Super-admin" value={2} />
              </TabList>
            )}
          </div>
          <TabPanel value={tab}>
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <CardContent>
                {actionid === "edit" &&
                  tab === 2 &&
                  (superAdmins === "failed" || superAdmins.length === 0) && (
                    <CardHeader
                      style={{ color: "red" }}
                      subheader="This websiteId has no Super Admin Try To Create One"
                    />
                  )}
                <Grid container spacing={4}>
                  {(superAdmins.length === 0 ||
                    superAdmins === "failed" ||
                    tab === 1 ||
                    tab === 2) &&
                    Object.entries(formik.values).map((value) => {
                      return (
                        <Grid
                          item
                          md={4}
                          xs={12}
                          sm={12}
                          xl={4}
                          inputProps={{
                            form: {
                              autocomplete: "off",
                            },
                          }}
                        >
                          {value[0] !== "websiteID" ? (
                            <TextField
                              fullWidth
                              autoComplete="none"
                              label={value[0]}
                              name={value[0]}
                              value={value[1]}
                              onChange={formik.handleChange}
                              error={
                                formik.touched[`${value[0]}`] &&
                                Boolean(formik.errors[`${value[0]}`])
                              }
                              helperText={
                                formik.touched[`${value[0]}`] &&
                                formik.errors[`${value[0]}`]
                              }
                              type="text"
                              variant="outlined"
                            />
                          ) : (actionid === "create" || actionid === "edit") &&
                            tab === 2 ? (
                            <TextField
                              fullWidth
                              sx={{ padding: "10px" }}
                              label="websiteID"
                              name={value[0]}
                              variant="outlined"
                              value={value[1]}
                              onChange={formik.handleChange}
                              error={
                                formik.touched[`${value[0]}`] &&
                                Boolean(formik.errors[`${value[0]}`])
                              }
                              helperText={
                                formik.touched[`${value[0]}`] &&
                                formik.errors[`${value[0]}`]
                              }
                            />
                          ) : (
                            <TextField
                              fullWidth
                              sx={{ padding: "10px" }}
                              label="websiteID"
                              name={value[0]}
                              variant="outlined"
                              value={value[1]}
                              onChange={formik.handleChange}
                              error={
                                formik.touched[`${value[0]}`] &&
                                Boolean(formik.errors[`${value[0]}`])
                              }
                              helperText={
                                formik.touched[`${value[0]}`] &&
                                formik.errors[`${value[0]}`]
                              }
                              InputProps={{
                                endAdornment: (
                                  <Button
                                    type="button"
                                    style={{
                                      backgroundColor: "#1693E2",
                                      color: "white",
                                      fontSize: "10px",
                                      Height: "fit-content",
                                    }}
                                    onClick={() => {
                                      dispatch(fetchWebsiteId());
                                    }}
                                  >
                                    Generate WebsiteID
                                  </Button>
                                ),
                              }}
                            />
                          )}
                        </Grid>
                      );
                    })}
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                {(superAdmins === "failed" ||
                  superAdmins.length === 0 ||
                  tab === 1 ||
                  tab === 2) && (
                  <Button color="primary" variant="contained" type="submit">
                    Save details
                  </Button>
                )}
              </CardActions>
            </form>

            <CardContent>
              {actionid === "edit" &&
                tab === 2 &&
                superAdmins !== "failed" &&
                superAdmins?.length !== 0 && (
                  <Table
                    columns={columnsList}
                    data={superAdmins === "failed" ? [] : superAdmins}
                    sort={sort}
                    id={"super-admin"}
                    handleSortChange={handleSortChange}
                    handlePageChange={handlePageChange}
                    page={page}
                    tableService={""}
                    rowsPerPage={rowsPerPage}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                  />
                )}
            </CardContent>
          </TabPanel>
        </TabContext>
      </Card>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  handleCloseLoading: PropTypes.func,
};

export default Form;
