// @flow
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import { fetchUser, UpdatePassword, updateProfile } from "redux/slices/user";
import SnackBar from "components/SnackBar";
import { Formik, useFormik } from "formik";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    overflow: "visible",
  },
  mtop: {
    marginTop: 24,
  },
  mtop0: {
    marginTop: 0,
  },
  content: {
    padding: 0,
  },
  uploadedFiles: {
    marginTop: 10,
  },
}));

const AccountDetails = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  const { user } = useSelector((state) => state.user);
  const [values, setValues] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
  });
  useEffect(() => {
    setValues({
      firstName: user.firstName,
      lastName: user.lastName,
    });
  }, [user]);
  const handleChange = ({ target: { name, value } }) => {
    setValues((values) => {
      return { ...values, [name]: value };
    });
  };

  const handleSubmit = () => {
    dispatch(updateProfile(values));
  };
  const validationSchema = Yup.object({
    currentPassword: Yup.string("Enter your current Password")
      .required(" current Password is required")

      .min(8)
      .max(40)
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
        "Must Contain at least 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    newPassword: Yup.string("Enter your new Password")
      .required(" new Password is required")

      .min(8)
      .max(40)
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
        "Must Contain at least 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
  });
  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      dispatch(UpdatePassword(values)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          resetForm();
        }
      });
    },
  });
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader subheader="Update Your Infos" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Typography>firstName</Typography>
              <TextField
                fullWidth
                name="firstName"
                onChange={handleChange}
                required
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography>lastName</Typography>
              <TextField
                fullWidth
                name="lastName"
                onChange={handleChange}
                required
                value={values.lastName}
                variant="outlined"
              />
            </Grid>
            <SnackBar />
          </Grid>
        </CardContent>
        <Divider />
        <CardActions style={{ float: "right" }}>
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            Save Changes
          </Button>
        </CardActions>
      </Card>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader subheader="Update Passwords" />
        <Divider />
        <CardContent>
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <Typography>Current Password</Typography>
                <TextField
                  fullWidth
                  name="currentPassword"
                  onChange={formik.handleChange}
                  required
                  value={formik.values.currentPassword}
                  helperText={
                    formik.touched.currentPassword &&
                    formik.errors.currentPassword
                  }
                  error={
                    formik.touched.currentPassword &&
                    Boolean(formik.errors.currentPassword)
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography>New Password</Typography>
                <TextField
                  fullWidth
                  name="newPassword"
                  onChange={formik.handleChange}
                  required
                  value={formik.values.newPassword}
                  variant="outlined"
                  helperText={
                    formik.touched.newPassword && formik.errors.newPassword
                  }
                  error={
                    formik.touched.newPassword &&
                    Boolean(formik.errors.newPassword)
                  }
                />
              </Grid>
              <SnackBar />
            </Grid>
            <CardActions style={{ float: "right" }}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
              >
                Update Password
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default AccountDetails;
