import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import Toolbar from '../../components/Toolbar/Toolbar';
import Table from '../../components/Table/Table';
import Collapse from '@material-ui/core/Collapse';
import Columns from '../../components/Columns';
import Filter from '../../components/Filter/Filter';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '7%', show: true, sortable: true},
  {accessor: 'name', label: 'Name', width: '30%', show: true},
  {accessor: 'position', label: 'Position', width: '7%', show: true, type: 'fieldValue'},
  {accessor: 'is_public', type: 'boolean', color: 'primary', width: '16%', label: 'Public', show: true,},
  {accessor: 'created_at', type: 'date', width: '25%', label: 'Created At', show: true,},
  {accessor: 'updated_at', type: 'date', width: '25%', label: 'Updated At', show: true,
  },

];
const fieldSearchable = [
    'name',
];
const fieldFilterable = [];

const DivisionList = () => {

  const [search, searchChange] = useState('');
  const [filters, filtersChange] = useState({});
  const [columns, columnsChange] = useState(columnsList);
  const [sort, sortChange] = useState({accessor: 'id', order: 'desc'});
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [columnsOpen, setColumnsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(24);
  const classes = useStyles();

  useEffect(() => {

  }, [page, sort, rowsPerPage]);

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };
  const toggleColumns = () => {
    setColumnsOpen(!columnsOpen);
  };
  const handleSortChange = (accessor) => {
    sortChange({
      accessor: accessor,
      order: sort.order === 'asc' ? 'desc' : 'asc',
    });
  };


  const handlePageChange = (event, page) => {
    setPage(page + 1);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  if (!data.payload) {
    //return (<Loading/>);
  }


  return (

      <div className={classes.root}>
        <Toolbar
            toggleFilters={toggleFilters} toggleColumns={toggleColumns}
            searchChange={searchChange}
            pageLink={'/contents/divisions'}
            searchMessage={'Search (ID )'}
        />
        <div className={classes.content}>
          <Collapse  in={columnsOpen}>
            <Columns columnsChange={columnsChange} columns={columns}/>
          </Collapse>
          <Collapse in={filtersOpen}>
            <Filter fields={fieldFilterable} values={filters} filtersChange={filtersChange} />

          </Collapse>
          <Table columns={columns} data={data}
                 handleSortChange={handleSortChange} sort={sort}
                 handlePageChange={handlePageChange} page={page}
                 tableService={''}
                 rowsPerPage={rowsPerPage}
                 handleRowsPerPageChange={handleRowsPerPageChange}
                 pageLink={'/contents/divisions'}
          />
        </div>
      </div>
  );
};

export default DivisionList;
