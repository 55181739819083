// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid, Paper,
  TextField, Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import {AsyncPaginate} from 'react-select-async-paginate';
import theme from '../../../theme';
import Checkbox from "@material-ui/core/Checkbox";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import DescriptionField from 'components/DescriptionField';
import LoadingApi from 'components/LoadingApi/LoadingApi';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    overflow: "visible",
  },
  mtop: {
    marginTop: 24,
  },

  mtop0: {
    marginTop: 0,
  },
  content: {
    padding: 0,
  },
  uploadedFiles: {
    marginTop: 10,
  },
}));


const Form = (props) => {
  const {id} = useParams();
  const {className, edit, ...rest} = props;
  const classes = useStyles();
  const [values, setValues] = useState({
    name: '',
    visible: true,
    description:undefined,
    subject: null,
    position: 1,
    type: 'course',
    chapterGroups: [],
  });
  const [message, setAlertMessage]=useState('');
  const [severity, setAlertSeverity]=useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (edit) {
    }
  }, []);
  const handleChange = (event) => {
    setValues({});
  };


  const handleSubmit = () => {

  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
    }

  return (
      <div {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" noValidate>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Card className={classes.root}>
                <CardContent>
                  <TextField
                      fullWidth
                      label="Name"
                      name="name"
                      onChange={handleChange}
                      required
                      value={values.name || ""}
                      variant="outlined"
                      size="small"
                  />
                  <Box mt={2} mb={1}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Description
                    </Typography>
                  </Box>
                  <Paper variant="outlined">
                    <DescriptionField
                      values={values}
                      handleBodyChange={handleChange}
                      value={values?.description}
                    />
                  </Paper>
                </CardContent>
              </Card>
              <Box mt={3}>
                <Card>
                  <CardHeader title="Subjects" />
                  <Divider />
                  <CardContent>
                    <Box mt={0} mb={1}>
                        <AsyncPaginate
                            value={values.subject}
                            onChange={handleChange}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option}
                            placeholder="Subjects"
                            isMulti={!edit}
                            additional={{
                              page: 1,
                            }}
                            styles={{
                              control: (base) => ({
                                ...base,
                                minHeight: '53px',
                              }),
                              placeholder: (base) => ({
                                ...base,
                                color: '#000',
                                fontSize: theme.typography.fontSize,
                                fontFamily: theme.typography.fontFamily,
                              }),
                            }}
                            menuPortalTarget={document.querySelector('body')}
                        />
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box mt={0}>
                <Card className={classes.root}>
                  <CardHeader title="Status" />
                  <Divider className={classes.mtop0} />
                  <CardContent className={classes.mtop0}>
                    <Box mt={0}>
                      <FormControlLabel
                          control={
                            <Checkbox
                                checked={values.visible}
                                onChange={handleChange}
                                value={values.visible}
                                name="visible"
                                size="small"
                            />
                          }
                          label="Visible ?"
                      />
                    </Box>
                    <Box mt={2}>
                      <TextField
                          fullWidth
                          label="Position"
                          name="position"
                          onChange={handleChange}
                          required
                          value={values.position || ''}
                          variant="outlined"
                      />
                    </Box>
                    <Box mt={2}>
                      <FormControl variant="outlined" fullWidth>
                      <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={values.type}
                          onChange={handleChange}
                          name="type"
                      >
                        <MenuItem value="course">Course</MenuItem>
                        <MenuItem value="recording">Recording</MenuItem>
                        <MenuItem value="exam">Exams</MenuItem>
                        <MenuItem value="divers">Divers</MenuItem>

                      </Select>
                      </FormControl>

                    </Box>

                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>

          <Box mt={3}>
            <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
            >
              Save details
            </Button>
          </Box>
        </form>
        <SnackBar
            open={open}
            message={message}
            severity={severity}
            handleClose={handleClose}
        />
 <LoadingApi open={loading} onClick={handleCloseLoading}/>
      </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
loading: PropTypes.bool,


};

export default Form;
