import React from "react";
import { makeStyles } from "@material-ui/styles";
import Form from "./components/Form";
import SimpleToolbar from "../../components/Toolbar/SimpleToolbar";
import { useSelector, useDispatch } from "react-redux";
import SnackBar from "../../components/SnackBar";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const UserCreate = (props) => {
  const { Link, pageLink, title, ...rest } = props;
  const classes = useStyles();
  const { open, msg } = useSelector((state) => state.snackbar);
  return (
    <div className={classes.root}>
      <SimpleToolbar Link={Link} pageLink={"/Websites"} title={"websites"} />
      <SnackBar open={open} msg={msg} />
      <div className={classes.content}>
        <Form actionid="create" superAdminAction />
      </div>
    </div>
  );
};

export default UserCreate;
