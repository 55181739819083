import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Form } from "./components";
import { useSelector } from "react-redux";
import SimpleToolbar from "../../components/Toolbar/SimpleToolbar";
import PropTypes from "prop-types";
import SnackBar from "../../components/SnackBar";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const UserEdit = (props) => {
  const { Link, pageLink, title, ...rest } = props;
  const { item } = useSelector((state) => state.websites);
  const classes = useStyles();
  const { open, msg } = useSelector((state) => state.snackbar);
  return (
    <div className={classes.root}>
      <SnackBar open={open} msg={msg} />
      <SimpleToolbar
        Link={Link}
        pageLink={"/website/Edit"}
        title={"Websites"}
      />
      <div className={classes.content}>
        <Form actionid="edit" website={item} />
      </div>
    </div>
  );
};

UserEdit.propTypes = {
  user: PropTypes.object,
};

export default UserEdit;
