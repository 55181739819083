import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./../../utils/axios";
import { openSnackBar } from "./snackbar";
const initialState = {
  websites: [],
  updatestatus: "idle",
  website: "",
  fetchstatus: "idle",
  websitestatus: "idle",
  deletestatus: "idle",
  poststatus: "idle",
  item: [],
  meta: [],
  deletestatus: "idle",
  createstatus: "idle",
  error: null,
};
export const fetchWebsites = createAsyncThunk(
  "websites/fetchWebsites",
  async (page) => {
    let data;
    try {
      const response = await axios.get(`/owner/websites/${page}`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getWebsite = createAsyncThunk(
  "websites/getWebsite",
  async (websiteID, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(`/owner/websites/${websiteID}`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const updateWebsite = createAsyncThunk(
  "websites/updateWebsite",
  async (website, thunkAPI) => {
    let data;
    try {
      const response = await axios.put(`/owner/websites/${website.id}`, {
        websiteID: website.websiteID,
        name: website.name,
        icon: website.icon,
        domain: website.domain,
        email:website.email,
        url:website.url
      });
      data = await response.data;

      if ((response.status = 200)) {
        thunkAPI.dispatch(fetchWebsites(""));
        thunkAPI.dispatch(
          openSnackBar({
            status: "success",
            msg: "website updated successfully",
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(openSnackBar({ status: "error", msg: err.message }));
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const deleteWebsite = createAsyncThunk(
  "websites/deleteWebsite",
  async (website, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(`/owner/websites/${website}`);
      data = await response.data;

      if ((response.status = 200)) {
        thunkAPI.dispatch(fetchWebsites(""));
        thunkAPI.dispatch(
          openSnackBar({
            status: "success",
            msg: "website deleted successfully",
          })
        );

        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(openSnackBar({ status: "error", msg: err.message }));
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const createWebsite = createAsyncThunk(
  "websites/createWebsite",
  async (website, thunkAPI) => {
    let data;
    console.log(website)
    try {
      const response = await axios.post(`/owner/websites`, website);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(fetchWebsites(""));
        thunkAPI.dispatch(
          openSnackBar({
            status: "success",
            msg: "website created successfully",
          })
        );

        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(openSnackBar({ status: "error", msg: err.message }));
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const createSuperAdminInWebsite = createAsyncThunk(
  "websites/createSuperAdminInWebsite",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(
        `/clients/websites/super-admins/create`,
        body
      );
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(
          openSnackBar({
            status: "success",
            msg: "Super-admin has successfully created",
          })
        );

        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(openSnackBar({ status: "error", msg: err.message }));
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "websites",
  initialState,
  reducers: {
    getItem: (state, action) => {
      state.item = action.payload;
    },
  },
  extraReducers: {
    [fetchWebsites.pending]: (state) => {
      state.fetchstatus = "loading";
    },
    [fetchWebsites.fulfilled]: (state, action) => {
      state.fetchstatus = "succeeded";
      state.websites = action.payload.data.docs;
      state.meta = action.payload.data.meta;
    },
    [fetchWebsites.rejected]: (state, action) => {
      state.fetchstatus = "failed";
      state.error = action.payload;
    },
    [updateWebsite.pending]: (state) => {
      state.updatestatus = "loading";
    },
    [updateWebsite.fulfilled]: (state, action) => {
      state.updatestatus = "succeeded";
      let Updateditem = state.websites.find(
        (id) => id._id === action.payload.data._id
      );
      Updateditem = action.payload.data;
    },
    [updateWebsite.rejected]: (state, action) => {
      state.updatestatus = "failed";
      state.error = action.payload;
    },
    [getWebsite.pending]: (state) => {
      state.websitestatus = "loading";
    },
    [getWebsite.fulfilled]: (state, action) => {
      state.websitestatus = "succeeded";
      state.website = action.payload.data;
    },
    [getWebsite.rejected]: (state, action) => {
      state.websitestatus = "failed";
      state.error = action.payload;
    },
    [deleteWebsite.pending]: (state) => {
      state.deletestatus = "loading";
    },
    [deleteWebsite.fulfilled]: (state, action) => {
      state.deletestatus = "succeeded";
      state.websites.filter((item) => item._id !== action.payload.data._id);
    },
    [deleteWebsite.rejected]: (state, action) => {
      state.deletestatus = "failed";
      state.error = action.payload;
    },

    [createWebsite.pending]: (state) => {
      state.createstatus = "loading";
    },
    [createWebsite.fulfilled]: (state, action) => {
      state.createstatus = "succeeded";
      state.websites.push(action.payload.data);
    },
    [createWebsite.rejected]: (state, action) => {
      state.createstatus = "failed";
      state.error = action.payload;
    },
  },
});
export const reducer = slice.reducer;
export const { getItem } = slice.actions;

export default slice;
